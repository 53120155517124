export const achievementsMap = {
    1: {
        id: 1,
        title: "Storyteller - Barbiere di Siviglia",
        description: "Guarda il video animato che racconta la storia del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    2: {
        id: 2,
        title: "Mazziere - Barbiere di Siviglia",
        description: "Scopri tutte le card interattive dei personaggi del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    3: {
        id: 3,
        title: "DJ - Barbiere di Siviglia",
        description: "Ascolta tutti gli estratti dei brani principali del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    4: {
        id: 4,
        title: "Perfezionista - Barbiere di Siviglia",
        description: "Scopri tutte le parole del glossario del livello 2!",
    },
    5: {
        id: 5,
        title: "Melomane - Barbiere di Siviglia",
        description: "Scarica lo schema musicale del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    9:{
        id: 9,
        title: "Meteorologo",
        description: "Ascolta il <i class='italic'>Temporale</i> del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    6: {
        id: 6,
        title: "Portalettere",
        description: "Scarica le lettere di Dall’Argine e Rossini!",
    },
    7: {
        id: 7,
        title: "Insaziabile",
        description: "Scarica la rilettura critica di Alberto Zedda!",
    },
    8: {
        id: 8,
        title: "Compositore",
        description: "Ascolta tutti gli esempi di autoimprestiti e crescendo rossiniano!",
    },
}

export const achievementProfilePictures = {
    cat: "/assets/user-profile-img-temp.jpg",
    default: {
        default : "/assets/avatars/default-avatar.png"
    },
    1 : {
        locked : "/assets/avatars/avatar-1-locked.png",
        default : "/assets/avatars/avatar-1.png",
    },
    2 : {
        locked : "/assets/avatars/avatar-2-locked.png",
        default : "/assets/avatars/avatar-2.png",
    },
    3 : {
        locked : "/assets/avatars/avatar-3-locked.png",
        default : "/assets/avatars/avatar-3.png",
    },
    4 : {
        locked : "/assets/avatars/avatar-4-locked.png",
        default : "/assets/avatars/avatar-4.png",
    },
    5 : {
        locked : "/assets/avatars/avatar-5-locked.png",
        default : "/assets/avatars/avatar-5.png",
    },
    9 : {
        locked : "/assets/avatars/avatar-5-locked.png",
        default : "/assets/avatars/avatar-5.png",
    },
    6 : {
        locked : "/assets/avatars/avatar-6-locked.png",
        default : "/assets/avatars/avatar-5.png",
    },
    7 : {
        locked : "/assets/avatars/avatar-5-locked.png",
        default : "/assets/avatars/avatar-5.png",
    },
    8 : {
        locked : "/assets/avatars/avatar-5-locked.png",
        default : "/assets/avatars/avatar-5.png",
    },

}
