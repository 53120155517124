<script setup>
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import DownloadableItem from "@/components/content/ContentDownloadableItem.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import ImageBox from "@/components/content/ContentImageBox.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;

const unlockAchievement = (index) => {
    if(!UserProgression.isAchievementUnlocked(index)){
        UserProgression.unlockAchievementById(index);
        UserNotifications.pushNotification("achievement", index);
    }
}
</script>

<template>
    <Title>Un unico libretto, molti compositori.</Title>
    <Textbox>
        Nel 1868 <GlossaryWord :word-id="37">Costantino Dall’Argine</GlossaryWord>, in quello che lui stesso definì
        come un “momento di audacia giovanile”,
        decise di dar vita al suo <i class="italic">Barbiere</i>, partendo proprio dal libretto di Cesare Sterbini.
    </Textbox>
    <Textbox>
        In vista della rappresentazione al <GlossaryWord :word-id="44">Teatro Comunale di Bologna</GlossaryWord>,
        Dall’Argine scrisse una lettera a Rossini,
        nella quale comunicava l’intenzione di dedicargli l’opera, manifestandogli stima e rispetto.
    </Textbox>
    <Textbox>
        Rossini accettò la dedica con qualche riserva, ironizzando sull'audacia che Dall’Argine aveva avuto
        nel musicare anch’egli il <i class="italic">Barbiere</i>.
    </Textbox>

    <ImageBox>
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-0-O0L2C0.png" alt="" />
    </ImageBox>

    <Textbox class="mt-8">
        Pochi mesi dopo la risposta di Rossini, l’11 novembre 1868 il <i class="italic">Barbiere</i> di Dall’Argine fece il suo debutto
        al Teatro Comunale di Bologna, dove si scatenò una vivace controversia tra i sostenitori di Rossini e coloro
        che invece apprezzavano la nuova interpretazione della commedia di de Beaumarchais.
    </Textbox>
    <Textbox>
        Tale episodio ricorda la
        contesa di mezzo secolo prima tra i sostenitori di Paisiello e quelli di Rossini al Teatro Argentina di Roma.
    </Textbox>

    <DownloadableItem :n-pages="2" src="https://io-bunny-cdn.b-cdn.net/ROF/documents/Lettera%20argine%20rossini.pdf" @download="unlockAchievement(6)">
        <template #title>La lettera di Dall’Argine a Rossini, e la sua risposta</template>
    </DownloadableItem>

    <Title right>Tanti “Barbieri” sono stati musicati nel corso dei secoli.</Title>

    <Textbox>
        Già prima di Paisiello, una serie di compositori, soprattutto tedeschi,
        si era confrontata con la commedia di de Beaumarchais.
    </Textbox>
    <Textbox>
        Tra questi, si ricordano:
        Friedrich Ludwig Benda (1776 a Berlino), il giovanissimo Joseph Weigl (1782), Zaccaria Elsberger (1782),
        Peter Schulz (1786) e il maltese Nicolò Isouard (1796).
    </Textbox>

    <ImageBox align="right">
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-1-O0L2C0.png" alt="" />
    </ImageBox>

    <Title right>Il primo <i class="italic">Barbiere</i> post-Rossini fu quello di Dall’Argine.</Title>

    <Textbox>
        Nello stesso anno in cui veniva rappresentato al Teatro Argentina il <i class="italic">Barbiere</i> rossiniano,
        ne appariva un altro di <GlossaryWord :word-id="38">Francesco Morlacchi</GlossaryWord> (<i class="italic">Il nuovo Barbiere di Siviglia</i>, Dresda 1816).
    </Textbox>
    <Textbox>
        Dopo Rossini, il primo a proporre un nuovo <i class="italic">Barbiere</i> fu proprio Dall'Argine,
        al quale fecero poi seguito altri compositori, tra cui Achille Graffigna, Leopoldo Cassone e Alberto Torazza.
    </Textbox>

    <FunFact theme="green" hidden-bot-desc hidden-legacy-p>
        <template #counter>CURIOSITÀ (1 su 3)</template>
        <template #title>Più libretti per una panoramica completa del <i class="italic">Barbiere.</i></template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-2/funfact-image-0-O0L2C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Come per ogni opera, per il <i class="italic">Barbiere</i> viene considerata fonte di riferimento
                        per il libretto quello della prima rappresentazione di Roma del 1816,
                        stampato da Crispino Puccinelli con il titolo <i class="italic">Almaviva o sia L'inutile precauzione.</i>
                    </p>
                </template>
            </FunfactParagraph>
            <FunfactParagraph right>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-2/funfact-image-1-O0L2C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Per avere una visione più completa e accurata è fondamentale prendere in considerazione i libretti
                        delle rappresentazioni successive, come quella di Bologna presso il
                        <GlossaryWord :word-id="45" theme="white">Teatro Contavalli</GlossaryWord> nell'estate
                        del 1816 e quella a Firenze al <GlossaryWord :word-id="46" theme="white">Teatro della Pergola</GlossaryWord> nell'autunno dello stesso anno, nei quali
                        compare per la prima volta il titolo <i class="italic">Il barbiere di Siviglia.</i>
                    </p>
                </template>
            </FunfactParagraph>
        </template>

    </FunFact>

    <Title><GlossaryWord :word-id="39">Alberto Zedda</GlossaryWord> e
        l’<GlossaryWord :word-id="34">edizione critica</GlossaryWord> del <i class="italic">Barbiere.</i></Title>
    <Textbox>
        L'editore che intendesse portare  in stampa il <i class="italic">Barbiere</i> di Rossini era  costretto a scegliere
        tra diverse versioni esistenti. Doveva mediare tra testo originario e pratica corrente,
        tra ciò che Rossini scrisse e ciò che veniva effettivamente rappresentato.
    </Textbox>
    <Textbox>
        A molti interpreti non fu possibile, per questo e altri motivi, confrontarsi con l’opera autentica,
        nella sua prima formulazione.
        O almeno, fino al 1968, anno in cui è stata pubblicata l’edizione critica del <i class="italic">Barbiere</i> di Rossini a cura
        di Alberto Zedda, edita da <GlossaryWord :word-id="40">Casa Ricordi</GlossaryWord>.
    </Textbox>

    <ImageBox>
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-9-O0L2C0.png" alt="" />
    </ImageBox>

    <DownloadableItem :n-pages="4" src="https://io-bunny-cdn.b-cdn.net/ROF/documents/Rilettura%20zedda.pdf" @download="unlockAchievement(7)">
        <template #title>Alberto Zedda racconta il suo lavoro di rilettura critica del <i class="italic">Barbiere di Siviglia</i></template>
    </DownloadableItem>

    <Title>“Maestro Zedda, ma questa partitura è sbagliata!” </Title>

    <Textbox>
        L’origine del fondamentale lavoro di rilettura critica del <i class="italic">Barbiere di Siviglia</i>
        ad opera di Alberto Zedda si può ricondurre ad un particolare incidente che lo vide protagonista nel 1959,
        quando era un giovane direttore d’orchestra.
    </Textbox>
    <Textbox>
        Si trovava a Cincinnati, nell’Ohio, per dirigere <i class="italic">Il barbiere.</i><br>
        Durante le prove del Finale Primo, uno dei due oboisti dell’orchestra,
        un professionista molto affermato, gli fece notare che “il tempo staccato nella <GlossaryWord :word-id="36">stretta</GlossaryWord>”,
        era impossibile da suonare correttamente con l’oboe, in quanto andava oltre i limiti tecnici dello strumento.<br>
        Zedda, incredulo, lo invitò a suonare la versione semplificata, prevista nella partitura Ricordi che stavano utilizzando.
    </Textbox>

    <ImageBox height="h-80">
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-2-O0L2C0.png" alt="" />
    </ImageBox>

    <Textbox class="mt-8">
        Una volta tornato in Italia, volle verificare sull’autografo del <i class="italic">Barbiere</i>, custodito a Bologna,
        il passaggio segnalato.<br>
        Poté così constatare che, in effetti, originariamente gli oboi non erano previsti
        nella stretta e che Rossini aveva assegnato le note incriminate agli ottavini, strumenti molto più agili.<br>
        L’oboista americano aveva quindi ragione.
    </Textbox>

    <ImageBox>
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-4-O0L2C0.png" alt="" />
    </ImageBox>

    <Textbox class="mt-8">
        Zedda colse l’occasione per sfogliare tutto il manoscritto rossiniano e,
        già al primo sguardo, rilevò delle notevoli differenze rispetto alla partitura conosciuta fino a quel momento:
        non trovò solo imprecisioni, ma veri e propri errori e manomissioni di vario genere, di cui prese nota con cura.
    </Textbox>
    <Textbox>
        Tempo dopo, quando si trovò a dover dirigere un <i class="italic">Barbiere</i> per la Rai,  trasferì, sui materiali d'orchestra della Ricordi,
        tutte le sue annotazioni, talmente tante da renderli inutilizzabili per altri noleggi.<br>
        Per questo motivo, Ricordi chiese un risarcimento danni a Zedda, il quale  replicò chiedendo,
        a sua volta, di essere risarcito del doppio per il lavoro di correzione e rettifica fatto sul materiale noleggiato,
        che non riportava la musica originale di Rossini.
    </Textbox>

    <ImageBox align="right">
        <img src="/assets/operas/barbieredisiviglia/level-2/content-image-3-O0L2C0.png" alt=""/>
    </ImageBox>

    <Textbox class="mt-8">
        La vicenda si risolse con l’ammissione dell’errore da parte di Ricordi e con l’annuncio della realizzazione
        di un’edizione critica basata sull’autografo del <i class="italic">Barbiere</i>, a cura della stessa casa editrice
        e affidata allo stesso Zedda. Edizione che verrà aggiornata nel 2008 con la
        <GlossaryWord :word-id="54">Fondazione Rossini.</GlossaryWord>
    </Textbox>
    <Textbox>
        Quell’edizione critica fu la prima non solo di un’opera del Maestro pesarese,
        ma anche di tutto il repertorio operistico italiano del primo Ottocento.
    </Textbox>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
