export const glossaryWordsGroupsMap = {
    1: {
        id: 1,
        title: "Struttura opera",
        items: [1, 32],
    },
    2: {
        id: 2,
        title: "Documenti opera",
        items: [6, 33, 34, 35],
    },
    3: {
        id: 3,
        title: "Tipi di opera",
        items: [7, 11, 12],
    },
    4: {
        id: 4,
        title: "Tipi di brani",
        items: [10, 21, 22, 36],
    },
    5: {
        id: 5,
        title: "Personaggi storici",
        items: [3, 4, 9, 23, 24, 25, 37, 38, 39, 40, 41, 42, 43],
    },
    6: {
        id: 6,
        title: "Ruoli opera",
        items: [13, 14],
    },
    7: {
        id: 7,
        title: "Tipi di voci",
        items: [15, 16, 17, 18, 19, 20],
    },
    8: {
        id: 8,
        title: "Luoghi chiave",
        items: [8, 26, 44, 45, 46],
    },
    9: {
        id: 9,
        title: "Titoli opere",
        items: [5, 27, 28, 53, 47, 48, 49, 50],
    },
    10: {
        id: 10,
        title: "Extra",
        items: [2, 31, 29, 30, 51, 52],
    },
}

export const glossaryWordsMap = {
    1: {
        id: 1,
        operaId : 1,
        word: "Atti",
        description:
            "Diverse parti in cui è strutturata un'opera lirica. Ci sono opere in uno, due e perfino cinque atti." +
            " I due atti di una commedia sono come il primo e il secondo tempo di un film. Aiutano a organizzare meglio la storia.",
    },
    2: {
        id: 2,
        operaId : 1,
        word: "Opera",
        description:
            "Forma di spettacolo che unisce elementi diversi." +
            " L'azione teatrale, attraverso la musica, il canto, le scene e i costumi, racconta una storia.",
    },
    3: {
        id: 3,
        operaId : 1,
        word: "Gioachino Rossini",
        description:
            "Compositore italiano del XIX secolo, noto soprattutto per le sue opere.",
    },
    4: {
        id: 4,
        operaId : 1,
        word: "Cesare Sterbini",
        description:
            "Librettista italiano del XIX secolo," +
            " famoso per aver scritto il libretto del Barbiere di Siviglia di Rossini." +
            " Il suo lavoro ha contribuito a rendere quest’opera un capolavoro musicale.",
    },
    5: {
        id: 5,
        operaId : 1,
        word: "Le barbier de Séville ou La précaution inutile",
        description:
            "Commedia teatrale francese del 1775 di Pierre-Augustin Caron de Beaumarchais." +
            " Come quando si trasforma un libro in film," +
            " Cesare Sterbini ha trasformato questa commedia teatrale in un testo per l’opera musicata da Rossini.",
    },
    6: {
        id: 6,
        operaId : 1,
        word: "Libretto",
        description: "Contiene le parole che vengono cantate durante l'opera e le didascalie sceniche." +
            " È come se fosse la sceneggiatura di un film.",
    },
    7: {
        id: 7,
        operaId : 1,
        word: "Opera buffa (o comica)",
        description:
            "Detta anche \"commedia in musica\" o \"dramma giocoso\"." +
            " Nata a Napoli come genere di opera per la gente comune," +
            " racconta storie semplici con lo scopo di divertire il pubblico.",
    },
    8: {
        id: 8,
        operaId : 1,
        word: "Teatro Argentina",
        description:
            "Uno dei principali teatri di Roma, inaugurato nel 1732 e tuttora in attività," +
            " famoso anche per la prima rappresentazione del Barbiere di Siviglia di Rossini il 20 febbraio 1816.",
    },
    9: {
        id: 9,
        operaId : 1,
        word: "Giovanni Paisiello",
        description:
            "Compositore italiano del XVIII secolo che ha contribuito" +
            " in maniera determinante allo sviluppo dell'opera buffa.",
    },
    10: {
        id: 10,
        operaId : 1,
        word: "Sinfonia",
        description:
            "Brano orchestrale introduttivo di un’opera affermatosi nel Seicento e divenuto," +
            " nel secolo successivo, una forma musicale autonoma e complessa.",
    },
    11: {
        id: 11,
        operaId : 1,
        word: "Opera seria",
        description: "Genere di opera lirica che tratta temi seri e drammatici," +
            " spesso caratterizzata da personaggi nobili e situazioni tragiche.",
    },
    12: {
        id: 12,
        operaId : 1,
        word: "Opera semiseria",
        description: "Genere operistico intermedio tra serio e buffo," +
            " in cui convivono personaggi, forme e stili tratti da entrambi i generi.",
    },
    13: {
        id: 13,
        operaId : 1,
        word: "Compositore",
        description: "Chi scrive la musica dell’opera.",
    },
    14: {
        id: 14,
        operaId : 1,
        word: "Copista",
        description: "Musicista che prepara le parti per cantanti, coro e orchestra.",
    },
    15: {
        id: 15,
        operaId : 1,
        word: "Tenore",
        description: "La più acuta delle voci maschili.",
    },
    16: {
        id: 16,
        operaId : 1,
        word: "Baritono",
        description: "Voce maschile intermedia tra il tenore e il basso.",
    },
    17: {
        id: 17,
        operaId : 1,
        word: "Mezzosoprano",
        description: "Voce femminile intermedia tra quella del contralto e del soprano.",
    },
    18: {
        id: 18,
        operaId : 1,
        word: "Basso",
        description: "La più grave tra le voci maschili.",
    },
    19: {
        id: 19,
        operaId : 1,
        word: "Soprano",
        description: "La più acuta delle voci femminili.",
    },
    20: {
        id: 20,
        operaId : 1,
        word: "Vocalità",
        description: "Insieme delle caratteristiche e capacità vocali di un cantante.",
    },
    21: {
        id: 21,
        operaId : 1,
        word: "Aria",
        description: "Brano vocale solistico, con un inizio, uno svolgimento e una conclusione, che permette" +
            " agli interpreti di mostrare le proprie abilità vocali ed esprimere diverse emozioni durante la performance.",
    },
    22: {
        id: 22,
        operaId : 1,
        word: "Arietta",
        description: "Breve aria di carattere leggero e sentimentale," +
            " cantata generalmente dalle seconde parti nel melodramma settecentesco.",
    },
    23: {
        id: 23,
        operaId : 1,
        word: "Francesco Sforza Cesarini",
        description: "Aristocratico romano. Impresario del Teatro Argentina" +
            " fatto erigere nel 1732 da suo nonno Giuseppe Sforza.",
    },
    24: {
        id: 24,
        operaId : 1,
        word: "Ercole Consalvi",
        description: "Segretario di Stato che comunicò al duca Francesco Sforza Cesarini che il Teatro Argentina," +
            " di proprietà del duca stesso, sarebbe dovuto rimanere aperto per la stagione teatrale di Carnevale e" +
            " avrebbe ospitato opere buffe.",
    },
    25: {
        id: 25,
        operaId : 1,
        word: "Jacopo Ferretti",
        description: "Nato a Roma il 16 luglio 1784. Scrisse poesie, prose e anche libretti d'opera.",
    },
    26: {
        id: 26,
        operaId : 1,
        word: "Teatro Valle",
        description: "Famoso teatro di Roma, inaugurato nel gennaio del 1727." +
            " Attivo tutto l'anno, era noto per le rappresentazioni di opere buffe, semiserie e commedie.",
    },
    27: {
        id: 27,
        operaId : 1,
        word: "L'Italiana in Algeri",
        description: "Dramma giocoso per musica in due atti su libretto di Angelo Anelli." +
            " Fu rappresentato per la prima volta al Teatro San Benedetto di Venezia il 22 maggio 1813." +
            " Rossini diresse quest’opera a Vicenza (1813) e a Napoli (1815).",
    },
    28: {
        id: 28,
        operaId : 1,
        word: "Torvaldo e Dorliska",
        description: "Dramma semiserio in due atti su libretto di Cesare Sterbini." +
            " Messo in scena per la prima volta al Teatro Valle di Roma il 26 dicembre 1815, fu accolto freddamente.",
    },
    29: {
        id: 29,
        operaId : 1,
        word: "Compagnia",
        description: "Gruppo di cantanti e musicisti" +
            " che si esibiscono insieme in produzioni teatrali.",
    },
    30: {
        id: 30,
        operaId : 1,
        word: "Claque",
        description: "Vocabolo francese che letteralmente significa “colpo dato col palmo della mano”." +
            " Nel gergo teatrale viene così chiamato un gruppo di persone pagate per applaudire (o fischiare)" +
            " una rappresentazione teatrale.",
    },
    31: {
        id:31,
        operaId : 1,
        word : "Carnevale",
        description : "La stagione teatrale di Carnevale iniziava il 26 dicembre e terminava con il Mercoledì delle ceneri." +
            " Era un periodo molto importante per gli artisti," +
            " che potevano guadagnare prima del lungo periodo di pausa della Quaresima, in cui gli spettacoli erano " +
            "quasi sempre vietati."
    },
    32: {
        id:32,
        operaId : 1,
        word: "Sinfonia",
        description : "Introduzione orchestrale di un'opera lirica, un oratorio o una cantata," +
            " usata per preparare il pubblico. Nel XVIII secolo, la sinfonia all’italiana aveva lo scopo" +
            " di catturare l'attenzione del pubblico e abbattere il brusio in sala. Originariamente articolata" +
            " in tre movimenti (Allegro–Adagio–Allegro), in seguito prevale la struttura in un singolo movimento Allegro," +
            " spesso preceduto da un Maestoso introduttivo. Rossini ne offre esempi brillanti."
    },
    33:{
        id: 33,
        operaId : 1,
        word : "Autografo",
        description : "Manoscritto d’autore di un’opera letteraria o musicale."
    },

    34:{
        id: 34,
        operaId : 1,
        word : "Edizione critica",
        description : "Versione di una partitura che punta a restituire  l'opera al suo stato originale," +
            " basandosi su studi approfonditi del materiale pervenuto ai giorni nostri."
    },
    35: {
        id:35,
        operaId : 1,
        word:"Appendici",
        description : "documenti che consentono di ricostruire la storia e l’evoluzione dell'opera nel corso del tempo." +
            " Possono contenere integrazioni, elaborazioni, modifiche o sostituzioni di parti vocali, strumentali di una composizione."
    },
    36: {
        id:36,
        operaId : 1,
        word: "Stretta",
        description : "Passaggio di una composizione, spesso usato nei finali operistici," +
            " dove il ritmo accelera gradualmente fino a raggiungere un momento culminante."
    },
    37:{
        id:37,
        operaId : 1,
        word : "Costantino Dall’Argine",
        description : "compositore che studiò al Conservatorio di Milano. Raggiunse grande successo come autore" +
            " di balli e ottenne una buona accoglienza con opere buffe come <i class='italic'>I due orsi (1867).</i>" +
            " Nel 1868 musicò il libretto di Sterbini per <i class='italic'>Il Barbiere di Siviglia</i>."
    },
    38: {
        id:38,
        operaId : 1,
        word: "Francesco Morlacchi",
        description : "Compositore che studiò al Liceo filarmonico di Bologna con Stanislao Mattei." +
            " Iniziò a scrivere opere nel 1807 e nel 1810 divenne direttore dell’Opera italiana di Dresda." +
            " Nel 1815 compose <i class='italic'>Il barbiere di Siviglia</i> sullo stesso libretto usato da  Paisiello." +
            " La prima rappresentazione ebbe luogo a Dresda il 27 aprile 1816 con buon successo."
    },
    39: {
        id:39,
        operaId : 1,
        word : "Alberto Zedda",
        description : "Direttore d'orchestra e musicologo. Vinse il Concorso internazionale della RAI per direttori" +
            " d’orchestra nel 1957. Svolse un’intensa attività operistica e fu consulente artistico (1981-1992)" +
            " e direttore artistico (2001-2015) del Rossini Opera Festival di Pesaro." +
            " Fondò e diresse l'Accademia Rossiniana di Pesaro (1989-2016)." +
            " Curò edizioni critiche di opere di Rossini e fu membro del Comitato editoriale della Fondazione Rossini."
    },
    40: {
        id:40,
        operaId : 1,
        word : "Casa Ricordi",
        description : "Casa editrice musicale milanese, fondata nel 1808 da Giovanni Ricordi (1785-1853)." +
            " Nel 1815 divenne copista della Scala con il diritto di pubblicarne il materiale e nel 1825 acquistò" +
            " l’archivio del teatro. Il figlio Giovanni fu amico di Rossini e pubblicò molte delle sue opere," +
            " sia in versione per canto e piano, sia in vari arrangiamenti per dilettanti."
    },
    41: {
        id:41,
        operaId : 1,
        word : "Geltrude Righetti Giorgi",
        description : "Contralto. Studiò a Bologna dove conobbe Rossini." +
            " Debuttò nel 1811 in un’opera di Cimarosa. Sposò l’avvocato Luigi Giorgi nel 1814 e rinunciò al canto." +
            " Nel 1815, su suggerimento dell’agente teatrale Francesco Zappi e con l'approvazione di Rossini," +
            " fu scritturata per la Stagione di Carnevale al Teatro Argentina di Roma, sostituendo Elisabetta Gafforini." +
            " Dopo il successo nell’<i class='italic'>Italiana in Algeri</i>, fu la prima interprete di Rosina nel" +
            " <i class='italic'> Barbiere di Siviglia</i> e, l’anno successivo, di Cenerentola nell’opera omonima."
    },
    42: {
        id: "42",
        operaId : 1,
        word : "Pier Luigi Pizzi",
        description :"Scenografo, regista e costumista italiano. Dal 1951 ha lavorato come scenografo" +
            " e costumista per spettacoli di prosa e lirica. Importante il suo sodalizio con Giorgio De Lullo" +
            " e la Compagnia dei Giovani, nonché la collaborazione con Luca Ronconi. Attivo nei principali teatri" +
            " e festival internazionali, ha ricevuto prestigiosi riconoscimenti."
    },
    43: {
        id:43,
        operaId : 1,
        word : "Luca Ronconi",
        description : "Regista e attore italiano. Ha diretto spettacoli prestigiosi e guidato il Teatro Stabile" +
            " di Torino (1988-93), il Teatro Stabile di Roma (1994-98), e il Piccolo Teatro di Milano" +
            " come direttore artistico (1999-2004)."
    },
    44: {
        id:44,
        operaId : 1,
        word : "Teatro Comunale di Bologna",
        description : "Costruito da Antonio Galli Bibbiena, inaugurato il 14 maggio 1763 con" +
            " <i class='italic'>Il trionfo di Clelia</i> di Gluck. Famoso per l'eccellente qualità degli spettacoli e" +
            " per la fama degli artisti internazionali. Rossini visse a Bologna per anni e vide molte delle" +
            " sue opere rappresentate al Comunale."
    },
    45: {
        id:45,
        operaId : 1,
        word : "Teatro Contavalli",
        description : "Costruito dal dottor Antonio Contavalli, inaugurato il 3 ottobre 1814. Presto vi fu rappresentata" +
            " <i class='italic'>L'Italiana in Algeri</i> di Rossini, cantata da Maria Marcolini. Dopo una chiusura temporanea durante la" +
            " Restaurazione, riaprì nell'estate del 1816 con una replica dell'<i class='italic'>Italiana in Algeri</i> e " +
            "<i class='italic'>Il Barbiere di Siviglia di Rossini.</i>"
    },
    46: {
        id:46,
        operaId : 1,
        word : "Teatro della Pergola di Firenze",
        description : "Inaugurato nel 1657 con <i class='italic'>Il podestà di Colognole</i> di Giovanni Andrea Moniglia." +
            " Progettato da Ferdinando Tacca, la sala unica era ispirata ai cortili dei palazzi rinascimentali."
    },
    47: {
        id:47,
        operaId : 1,
        word : "Aureliano in Palmira",
        description : "Dramma serio in due atti di Gioachino Rossini su libretto di Felice Romani." +
            " Prima rappresentazione a Milano, presso il Teatro alla Scala, il 26 dicembre 1813."
    },
    48: {
        id:48,
        operaId : 1,
        word : "Elisabetta regina d’Inghilterra",
        description : "Dramma per musica in due atti di Gioachino Rossini su libretto di Giovanni Schmidt." +
            " Rappresentata per la prima volta a Napoli, presso il Teatro San Carlo, il 4 ottobre del 1815," +
            " con protagonista Isabella Colbran."
    },
    49: {
        id:49,
        operaId : 1,
        word : "Sigismondo",
        description : "Dramma in due atti di Gioachino Rossini su libretto di Giuseppe Foppa." +
            " Prima rappresentazione a Venezia, presso il Teatro La Fenice, il 26 dicembre 1814."
    },
    50: {
        id:50,
        operaId : 1,
        word : "Cantata in onore del compleanno di Sua Maestà il Re Ferdinando IV",
        description : "Cantata <i class='italic'>Pel faustissimo giorno natalizio di Sua Maestà il re Ferdinando IV, nostro augusto sovrano</i>" +
            ", composta da Rossini nel 1815-1816 ed eseguita il 12 gennaio 1816 al Teatro San Carlo di Napoli in onore di Ferdinando di Borbone (1751-1825)."
    },
    51: {
        id:51,
        operaId : 1,
        word : "Autoimprestiti",
        description : "Pratica che consiste nel riutilizzo da parte dei compositori di temi," +
            " frasi, movimenti e interi pezzi delle loro opere precedenti. Questo fenomeno, comune tra il Settecento" +
            " e il primo Ottocento, è frequente anche nelle opere di Rossini."
    },
    52: {
        id:52,
        operaId : 1,
        word : "Crescendo rossiniano",
        description : "Tecnica utilizzata per creare situazioni paradossali o estremamente animate," +
            " impiegata da Rossini e altri compositori come Mayr e Generali. Consiste nella ripetizione" +
            " di brevi incisi musicali che aumentano progressivamente la sonorità, grazie all'ingresso progressivo" +
            " di tutti gli strumenti, dal pianissimo al fortissimo."
    },
    53: {
        id: 53,
        operaId : 1,
        word : "<i>La pietra del paragone</i>",
        description : "Melodramma giocoso in due atti su libretto di Luigi Romanelli. Fu rappresentato per la prima" +
            " volta il 26 settembre 1812 a Milano, Teatro alla Scala. La pietra del paragone fu la prima commissione" +
            " di Rossini per la Scala di Milano, il principale teatro di uno dei maggiori centri culturali italiani."
    },
    54: {
        id: 54,
        operaId : 1,
        word : "<i>Fondazione Rossini</i>",
        description : "Dal 1940 gestisce il patrimonio materiale e immateriale del Maestro da lui lasciato" +
            " alla città di Pesaro. Dal 1979 sta pubblicando l'edizione critica di tutti i lavori di Rossini," +
            " non solo le opere: una gigantesca impresa che terminerà tra qualche decennio al ritmo di un volume l'anno."
    }
}
